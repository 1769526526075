<template>
    <div class="subjectRecord-box">
        <!-- title栏 -->
        <div class="title-box">
            <ul>
                <li>
                    <a href="javascript:;">
                        做题记录
                    </a>
                </li>
            </ul>
        </div>
        <!-- 内容 -->
        <div class="content-box">
            <div class="record-title-box">
                <ul>
                    <li v-for="(item, index) in recordTab" :key="index">
                        <p>{{item}}</p>
                    </li>
                </ul>
            </div>
            <div class="record-content-box">
                <ul class="record-ul">
                    <li class="record-li" v-for="(item,index) in recordList" :key="index">
                        <p clsss="bold">单选题</p>
                        <p>{{item.totalQuestion}}</p>
                        <p>{{item.totalDo}}</p>
                        <p>{{item.trueCount}}</p>
                        <p>{{item.errorCount}}</p>
                        <p clsss="font-bold">{{item.accuracy}}</p>
                        <a href="javascript:;" @click="toShow(item.resultId)">
                            查看解析
                        </a>
                    </li>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        prev-text="<"
                        next-text=">"
                        :page-sizes="[2, 5, 10, 20]"
                        :current-page="pageNumber"
                        :total="total"
                        :page-size="pageSize"
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle">
                    </el-pagination>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'subjectRecord',
    data() {
        return {
            recordTab: ['题型','总题数','做题数','正题数','错题数','正确率','试题解析'],
            recordList: [],
            total: 0,
            pageSize: 8,
            pageNumber: 1,
        }
    },
    mounted() {
        this.getRecord()
    },
    methods: {
        getRecord() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/practiceRecord.html',
                data: {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize
                }
            }).then((res) => {
                this.total = res.data.result.total;
                this.recordList = res.data.result.list
            })
        },
        toShow(resultId) {
            this.$router.push({
                name: 'showquestion',
                params:{resultId:resultId}
            })
        },
        // 页面跳转
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageNumber = 1;
            this.getRecord();
            console.log(`每页 ${val} 条`);
        },
        currentChangeHandle(val) {
            this.pageNumber = val;
            this.getRecord();
            console.log(`当前页: ${val}`)
        },
    }
}
</script>

<style lang="scss" scoped>
    .subjectRecord-box {
        .title-box {
            padding-left: 30px;
            box-sizing: border-box;
            width: 950px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-bottom: 20px;
            ul {
                display: flex;
                li {
                    a {
                        line-height: 70px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FA4B66;
                        border-bottom: 3px solid #FA4B66;
                        padding-bottom: 22px;
                        
                }
            }
        }
        }
        .content-box {
            padding: 40px 25px;
            box-sizing: border-box;
            width: 950px;
            height: 771px;
            background: #FFFFFF;
            border-radius: 10px;
            .record-title-box {
                padding-left: 35px;
                padding-right: 35px;
                padding-top: 19px;
                margin-bottom: 12px;
                width: 900px;
                height: 50px;
                background: #F9F9F9;
                box-sizing: border-box;
                ul {
                    display: flex;
                    justify-content: space-between;
                    li {
                        p {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
            }
            .record-content-box {
                .record-ul {
                    padding-left: 27px;
                    .el-pagination {
                        margin-top: 30px;
                        text-align: center;
                    }
                    .record-li {
                        display: flex;
                        align-items: center;
                        height: 70px;
                        border-bottom: 1px solid #EEEEEE;
                        p {
                            width: 42px;
                            text-align: center;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            &:nth-child(1) {
                                margin-right: 82px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #333333;
                            }
                            &:nth-child(2) {
                                margin-right: 89px;
                            }
                            &:nth-child(3) {
                                margin-right: 90px;
                            }
                            &:nth-child(4) {
                                margin-right: 89px;
                            }
                            &:nth-child(5) {
                                margin-right: 91px;
                            }
                            &:nth-child(6) {
                                margin-right: 76px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #333333;
                            }
                        }
                        a {
                            display: inline-block;
                            width: 80px;
                            height: 30px;
                            border: 1px solid #FA4B66;
                            border-radius: 4px;
                            text-align: center;
                            line-height: 30px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FF5A74;
                        }
                    }
                }
            }
         }
    }
</style>