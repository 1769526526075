<template>
    <div class="myclassBox">
        <!-- title栏 -->
        <div class="title-box">
            <ul>
                <li>
                    <a href="javascript:;">
                        我的课程
                    </a>
                </li>
            </ul>
        </div>
        <!-- 内容 -->
        <div class="content-box">
            <div class="content-list-box" v-if="this.classList">
                <ul>
                    <li v-for="(item, index) in classList.slice((dictCurrentPage- 1)*dictPageSize,dictCurrentPage*dictPageSize)" :key="index">
                        <div class="img-box">
                            <img :src="item.courseLogo" alt="">
                        </div>
                        <div class="text-box">
                            <div class="text-title">
                                <p class="title-top">{{item.courseName}}</p>
                                <p class="title-sub"><i></i><span>{{item.viewCount}}</span></p>
                            </div>
                            <div class="bottom-box">
                                <p class="title-top"><span class="dis-price">¥{{item.price}}</span><span class="ori-price">¥{{item.marketPrice}}</span></p>
                                <div class="button">
                                    <a href="javascript:;" v-if="item.courseType === 'toVideo'" @click="jumpClass(item.courseId)">开始上课</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <el-pagination
                    background
                    layout="prev, pager, next"
                    prev-text="<"
                    next-text=">"
                    :page-sizes="[2, 5, 10, 20]"
                    :current-page="dictCurrentPage"
                    :total="waitTotal"
                    :page-size="dictPageSize"
                    @current-change="current_change">
                </el-pagination>
            </div>
            <div v-else>
                暂无内容
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'myclass',
    data() {
        return {
            classList: [],
            waitTotal: 0,
            dictPageSize:6,
            dictCurrentPage:1,
        }
    },
    mounted() {
        this.getClass()
    },
    methods: {
        // 获取课程
        getClass() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/myCourse.html'
            }).then((res) => {
                this.classList = res.data.result.list;
                this.waitTotal = this.classList.length;
            })
        },
        current_change(val) {
            this.dictCurrentPage = val;
        },
        jumpClass(id) {
            this.$router.push({
                name:'classdetail',
                params: {
                    "id":id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .el-pagination {
        text-align: center;
    }
    .myclassBox {
        .title-box {
            padding-left: 30px;
            box-sizing: border-box;
            width: 950px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-bottom: 20px;
            ul {
                display: flex;
                li {
                    a {
                        line-height: 70px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FA4B66;
                        border-bottom: 3px solid #FA4B66;
                        padding-bottom: 22px;
                        
                }
            }
        }
        }
        .content-box {
            padding: 40px 25px;
            box-sizing: border-box;
            width: 950px;
            height: 771px;
            background: #FFFFFF;
            border-radius: 10px;
            .content-list-box {
                ul {
                    display: flex;
                    // justify-content: space-between;
                    flex-wrap: wrap;
                    li {
                        margin-bottom: 18px;
                        margin-right: 10px;
                        width: 288px;
                        height: 261px;
                        border: 1px solid #EAEAEA;
                        border-radius: 6px;
                        .img-box {
                            width: 288px;
                            height: 152px;
                        }
                        .text-box {
                            width: 288px;
                            height: 109px;
                            padding: 16px;
                            box-sizing: border-box;
                            .text-title {
                                margin-bottom: 8px;
                                .title-top {
                                    margin-bottom: 11px;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .title-sub span {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                }
                            }
                        }
                        .bottom-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .title-top {
                                .dis-price {
                                    margin-right: 9px;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #FF5A74;
                                }
                                .ori-price {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    text-decoration: line-through;
                                    color: #999999;
                                }
                            }
                            .button a {
                                display: block;
                                width: 90px;
                                height: 30px;
                                background: #FA4B66;
                                border-radius: 15px;
                                text-align: center;
                                line-height: 30px;
                                color: #FFFFFF;
                            }
                        }
                    }
                    }
                }
            }
    }
</style>