<template>
    <div class="changePasswordBox">
        <!-- title栏 -->
        <div class="title-box">
            <ul>
                <li>
                    <a href="javascript:;">
                        修改密码
                    </a>
                </li>
            </ul>
        </div>
        <!-- 内容 -->
        <div class="content-box">
            <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
                <el-form-item label="新密码" prop="oldpassword">
                    <el-input :type="passw" v-model="ruleForm.oldpassword" style="width: 295px" placeholder="请输入密码">
                        <i slot="suffix" :class="icon" @click="showPass"></i>
                    </el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="newpassword">
                    <el-input :type="passw" v-model="ruleForm.newpassword" style="width: 295px" placeholder="请输入新密码">
                        <i slot="suffix" :class="icon" @click="showPass"></i>
                    </el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="ruleForm.phone" style="width: 295px" placeholder="请输入手机号"></el-input>
                </el-form-item>
                    <el-form-item prop="code" label="验证码">
                        <el-input v-model="ruleForm.code" placeholder="请输入验证码" style="width: 150px"></el-input>
                    <el-button type="button" @click="sendcode" :disabled="disabled" v-if="disabled==false">发送验证码
                    </el-button>
                        <el-button type="button" @click="sendcode" :disabled="disabled" v-if="disabled==true">{{btntxt}}
                    </el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" class="confineButton">确认修改</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    name: 'changepassword',
    data() {
        return {
            passw:"password",
            icon:"el-input__icon el-icon-view",
            ruleForm: {
                oldpassword: '',
                newpassword: '',
                phone: '',
                code: ''
            },
            rules: {
                oldpassword: [
                    { required: true, message: '(密码可使用字母加数字或符号的组合，6-20个字符)', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在6 到 20个字符', trigger: 'blur' }
                ],
                newpassword: [
                    { required: true, message: '(密码可使用字母加数字或符号的组合，6-20个字符)', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在6 到 20个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: "请输入手机号码", trigger: "blur" },
                    { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                    {
                        pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
                        //pattern: /^1[3456789]\d{9}$/,
                        message: "请输入正确的手机号码",
                    }
                ],
                code: [
                     { required: true, message: "请输入验证码", trigger: "blur" },
                     { min: 6, max: 6, message: "请输入6位验证码", trigger: "blur" },
                ]
            },
            disabled: false,
            time: 0,
            btntxt: "重新发送",
        }
    },
    methods:{
        showPass(){
            if( this.passw=="text"){
                this.passw="password";
                this.icon="el-input__icon el-icon-view";
            }else {
                this.passw="text";
                this.icon="el-input__icon el-icon-loading";
            }
        },
        //手机验证发送验证码
        sendcode() {
            const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
            if (this.ruleForm.phone == '') {
                this.$message({
                    message:'手机号不能为空',
                    center: true
                })
                return
            }
            if (!reg.test(this.ruleForm.phone)) {
                this.$message({
                    message:'请输入正确的手机号',
                    center:true
                })
                return
            } else {
                // console.log(this.ruleForm.phone);
                this.axios({
                    method: 'POST',
                    url: 'api/v2/yun/member/getSmsCode.html',
                    data: {
                        mobile: this.ruleForm.phone,
                    }
                })
                this.$message({
                    message: '发送成功',
                    type: 'success',
                    center:true
                });
                this.time = 60;
                this.disabled = true;
                this.timer();
            }
        },
        //60S倒计时
        timer() {
            if (this.time > 0) {
                this.time--;
                this.btntxt = this.time + "s后重新获取";
                setTimeout(this.timer, 1000);
            } else {
                this.time = 0;
                this.btntxt = "获取验证码";
                this.disabled = false;
            }
        },
        // 确认提交
        submitForm() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/updatePassword.html',
                data: {
                    mobile: this.ruleForm.phone,
                    smsCode: this.ruleForm.code,
                    newPassword: this.ruleForm.newpassword
                }
            }).then((res) => {
                console.log(res)
                // this.$message.success('密码已修改成功');
                if(res.data.code == 2108) {
                    this.$message.warring(res.data.msg);
                } else if(res.data.code == 2103) {
                    this.$message.warring(res.data.msg);
                } else if(res.data.code == 200) {
                    this.$message.success('密码已修改成功');
                    localStorage.setItem('token',res.data.result.token)
                }
                // sessionStorage.removeItem('key');
                // localStorage.removeItem('key');
                // localStorage.removeItem('token');
                // this.$store.commit("$_removeStorage")
                // this.$store.commit('saveCartCount','0');
                // this.$router.push('/login');
            })
        }
    },
}
</script>
<style lang="scss">
    .changePasswordBox {
        .title-box {
            padding-left: 30px;
            box-sizing: border-box;
            width: 950px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-bottom: 20px;
            ul {
                display: flex;
                li {
                    a {
                        line-height: 70px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FA4B66;
                        border-bottom: 3px solid #FA4B66;
                        padding-bottom: 22px;
                    }
                }
            }
        }
        .content-box {
            padding-top: 58px;
            box-sizing: border-box;
            width: 950px;
            height: 771px;
            background: #FFFFFF;
            border-radius: 10px;
            .el-form-item__error {
                color: #F56C6C;
                font-size: 12px;
                line-height: 1;
                padding-top: 4px;
                position: absolute;
                top: 11px;
                left: 36%;
            }
            .el-button {
                // position: absolute;
                // left: 22%;
                margin-left: 12px;
                background: #FA4B66;
                color: #FFFFFF;
                border-color: #FA4B66;
            }
        }
    }
</style>