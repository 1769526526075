<template>
    <div class="myOrder-box">
         <!-- title栏 -->
        <div class="title-box">
            <ul class="title-ul">
                <li class="title-li" @click="num=0" :class="{active:num==0}">全部订单</li>
                <li class="title-li" @click="num=1" :class="{active:num==1}">已付款{{isPayCount}}</li>
                <li class="title-li" @click="num=2" :class="{active:num==2}">待付款{{waitPayCount}}</li>
                <li class="title-li" @click="num=3" :class="{active:num==3}">已取消{{cancelPayCount}}</li>
            </ul>
        </div>
        <!-- 内容 -->
        <div class="content-box">
            <div class="tab-box" v-show="num==0">
                <div class="header-box">
                    <ul class="tab-ul">
                        <li class="tab-one">
                            订单详情
                        </li>
                        <li class="tab-two">
                            总计
                        </li>
                        <li class="tab-three">
                            状态
                        </li>
                        <li class="tab-four">
                            操作
                        </li>
                    </ul>
                </div>
                <div v-if="orderList.length == 0">
                    暂无订单
                </div>
                <div v-else>
                    <div class="tab-content-box">
                        <ul class="orderNum-list-ul">
                            <li class="orderNum-list-li" v-for="(item,index) in orderList" :key="index">
                                <div class="orderNum-box">
                                    <div class="orderNum-left">
                                        <i class="orderNum-icon"></i>
                                        <p class="orderNum-text">订单号：<span class="orderNum-color">{{item.tradeNo}}</span></p>
                                    </div>
                                    <div class="orderNum-right">
                                        <p>{{item.createDate}}</p>
                                    </div>
                                </div>
                                <div class="orderNum-list">
                                    <div class="list-slide list-one">
                                        <label v-for="(textItem,index) in item.courseList" :key="index">
                                            <p @click="jumpClass(textItem.courseId)">{{textItem.courseName}}</p>
                                        </label>
                                        
                                    </div>
                                    <div class="list-slide list-two">
                                        <p>¥{{item.amount}}</p>
                                    </div>
                                    <div class="list-slide list-three">
                                        <p>{{item.payStatus}}</p>
                                    </div>
                                    <div class="list-four">
                                        <div v-show="item.payStatus == '待付款'">
                                            <p class="list-play" @click="quickPay(item.orderId)">立即支付</p>
                                            <p class="list-text" @click="detailJump(item.orderId)">订单详情</p>
                                            <p class="list-text" @click="cancelPay(item.orderId)">取消支付</p>
                                        </div>
                                        <div v-show="item.payStatus == '已付款'" class="four-allpay">
                                            <p class="list-text" @click="detailJump(item.orderId)">订单详情</p>
                                        </div>
                                        <div v-show="item.payStatus == '已取消'" class="four-allpay">
                                            <p class="list-text" @click="detailJump(item.orderId)">订单详情</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            prev-text="<"
                            next-text=">"
                            :page-sizes="[2, 5, 10, 20]"
                            :current-page="pageNumber"
                            :total="total"
                            :page-size="pageSize"
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle">
                        </el-pagination>
                    </div>
                </div>
                
            </div>
            <div class="tab-box" v-show="num==1">
                <div class="header-box">
                    <ul class="tab-ul">
                        <li class="tab-one">
                            订单详情
                        </li>
                        <li class="tab-two">
                            总计
                        </li>
                        <li class="tab-three">
                            状态
                        </li>
                        <li class="tab-four">
                            操作
                        </li>
                    </ul>
                </div>
                <div v-if="isPayList.length == 0">
                    暂无订单
                </div>
                <div v-else>
                    <div class="tab-content-box">
                        <ul class="orderNum-list-ul">
                            <li class="orderNum-list-li" v-for="(item,index) in isPayList.slice((dictCurrentPage- 1)*dictPageSize,dictCurrentPage*dictPageSize)" :key="index">
                                <div class="orderNum-box">
                                    <div class="orderNum-left">
                                        <i class="orderNum-icon"></i>
                                        <p class="orderNum-text">订单号：<span class="orderNum-color">{{item.tradeNo}}</span></p>
                                    </div>
                                    <div class="orderNum-right">
                                        <p>{{item.createDate}}</p>
                                    </div>
                                </div>
                                <div class="orderNum-list">
                                    <div class="list-slide list-one">
                                        <label v-for="(textItem,index) in item.courseList" :key="index">
                                            <p @click="jumpClass(textItem.courseId)">{{textItem.courseName}}</p>
                                        </label>
                                        
                                    </div>
                                    <div class="list-slide list-two">
                                        <p>¥{{item.amount}}</p>
                                    </div>
                                    <div class="list-slide list-three">
                                        <p>{{item.payStatus}}</p>
                                    </div>
                                    <div class="list-four">
                                        <div v-show="item.payStatus == '已付款'" class="four-allpay">
                                            <p class="list-text" @click="detailJump(item.orderId)">订单详情</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            prev-text="<"
                            next-text=">"
                            :page-sizes="[2, 5, 10, 20]"
                            :current-page="dictCurrentPage"
                            :total="isPayTotal"
                            :page-size="dictPageSize"
                            @current-change="current_change">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="tab-box" v-show="num==2">
                <div class="header-box">
                    <ul class="tab-ul">
                        <li class="tab-one">
                            订单详情
                        </li>
                        <li class="tab-two">
                            总计
                        </li>
                        <li class="tab-three">
                            状态
                        </li>
                        <li class="tab-four">
                            操作
                        </li>
                    </ul>
                </div>
                <div v-if="waitPayList.length == 0">
                    暂无订单
                </div>
                <div v-else>
                    <div class="tab-content-box">
                        <ul class="orderNum-list-ul">
                            <li class="orderNum-list-li" v-for="(item,index) in waitPayList.slice((dictCurrentPage- 1)*dictPageSize,dictCurrentPage*dictPageSize)" :key="index">
                                <div class="orderNum-box">
                                    <div class="orderNum-left">
                                        <i class="orderNum-icon"></i>
                                        <p class="orderNum-text">订单号：<span class="orderNum-color">{{item.tradeNo}}</span></p>
                                    </div>
                                    <div class="orderNum-right">
                                        <p>{{item.createDate}}</p>
                                    </div>
                                </div>
                                <div class="orderNum-list">
                                    <div class="list-slide list-one">
                                        <label v-for="(textItem,index) in item.courseList" :key="index">
                                            <p @click="jumpClass(textItem.courseId)">{{textItem.courseName}}</p>
                                        </label>
                                        
                                    </div>
                                    <div class="list-slide list-two">
                                        <p>¥{{item.amount}}</p>
                                    </div>
                                    <div class="list-slide list-three">
                                        <p>{{item.payStatus}}</p>
                                    </div>
                                    <div class="list-four">
                                        <div v-show="item.payStatus == '待付款'" class="four-allpay">
                                            <p class="list-text" @click="detailJump(item.orderId)">订单详情</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            prev-text="<"
                            next-text=">"
                            :page-sizes="[2, 5, 10, 20]"
                            :current-page="dictCurrentPage"
                            :total="waitTotal"
                            :page-size="dictPageSize"
                            @current-change="current_change">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="tab-box" v-show="num==3">
                <div class="header-box">
                    <ul class="tab-ul">
                        <li class="tab-one">
                            订单详情
                        </li>
                        <li class="tab-two">
                            总计
                        </li>
                        <li class="tab-three">
                            状态
                        </li>
                        <li class="tab-four">
                            操作
                        </li>
                    </ul>
                </div>
                <div v-if="cancelPayList.length == 0">
                    暂无订单
                </div>
                <div v-else>
                     <div class="tab-content-box">
                        <ul class="orderNum-list-ul">
                            <li class="orderNum-list-li" v-for="(item,index) in cancelPayList.slice((dictCurrentPage- 1)*dictPageSize,dictCurrentPage*dictPageSize)" :key="index">
                                <div class="orderNum-box">
                                    <div class="orderNum-left">
                                        <i class="orderNum-icon"></i>
                                        <p class="orderNum-text">订单号：<span class="orderNum-color">{{item.tradeNo}}</span></p>
                                    </div>
                                    <div class="orderNum-right">
                                        <p>{{item.createDate}}</p>
                                    </div>
                                </div>
                                <div class="orderNum-list">
                                    <div class="list-slide list-one">
                                        <label v-for="(textItem,index) in item.courseList" :key="index">
                                            <p @click="jumpClass(textItem.courseId)">{{textItem.courseName}}</p>
                                        </label>
                                        
                                    </div>
                                    <div class="list-slide list-two">
                                        <p>¥{{item.amount}}</p>
                                    </div>
                                    <div class="list-slide list-three">
                                        <p>{{item.payStatus}}</p>
                                    </div>
                                    <div class="list-four">
                                        <div v-show="item.payStatus == '已取消'" class="four-allpay">
                                            <p class="list-text" @click="detailJump(item.orderId)">订单详情</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            prev-text="<"
                            next-text=">"
                            :page-sizes="[2, 5, 10, 20]"
                            :current-page="dictCurrentPage"
                            :total="cancelTotal"
                            :page-size="dictPageSize"
                            @current-change="current_change">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            //默认第一个选项卡
            num: 0,
            orderList: [],
            isPayList: [],
            waitPayList: [],
            cancelPayList: [],
            total: 0,
            pageSize: 3,
            pageNumber: 1,
            cancelPayCount: '',
            isPayCount: '',
            waitPayCount: '',

            cancelTotal:0,
            waitTotal: 0,
            isPayTotal: 0,
            dictCurrentPage:1,
            dictPageSize:3
        }
    },
    mounted() {
        this.getOrder();
        this.getCount();
        this.getState();
    },
    methods: {
        // 取消支付
        cancelPay(orderId) {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/order/close_order.html',
                data: {
                    orderId: orderId,
                }
            }).then(() => {
                this.$message.success('课程订单已取消')
                this.getOrder();
            })
        },
        // 立即支付
        quickPay(orderId) {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/order/get_remainder_time.html',
                data: {
                    orderId: orderId,
                }
            }).then(() => {
                // let validTime = res.data.result.remainderTime;
                this.$router.push({
                    path: '/orderPay',
                    query: {
                        orderId: orderId,
                    }
                });
            })
        },
        // 获取订单
        getOrder() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/order/myOrders.html',
                data: {
                    pageSize: this.pageSize,
                    pageNumber: this.pageNumber
                }
            }).then((res) => {
                console.log(res)
                this.orderList = res.data.result.list;
                this.total = res.data.result.total;
            })
        },
        // 获取订单各状态
        getState() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/order/myOrders.html',
            }).then((res) => {
                for(let i = 0;i<res.data.result.list.length;i++) {
                    if(res.data.result.list[i].payStatus == '已付款') {
                        this.isPayList.push(res.data.result.list[i])
                        this.isPayTotal = this.isPayList.length;
                    } else if(res.data.result.list[i].payStatus == '待付款') {
                        this.waitPayList.push(res.data.result.list[i]);
                        this.waitTotal = this.waitPayList.length;
                    } else if(res.data.result.list[i].payStatus == '已取消') {
                        this.cancelPayList.push(res.data.result.list[i])
                        this.cancelTotal = this.cancelPayList.length;
                    }
                }
            })
        },
        // 获取订单的状态
        getCount() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/order/count.html'
            }).then((res) => {
                this.cancelPayCount = res.data.result.cancelPayCount;
                this.isPayCount = res.data.result.isPayCount;
                this.waitPayCount = res.data.result.waitPayCount;
            })
        },
        // 跳转
        detailJump(orderId) {
            this.$router.push({
                path: '/orderDetail',
                query: {
                    orderId: orderId,
                }
            });
        },
        // 课程跳转
        jumpClass(courseId) {
            this.$router.push({
                name: 'classdetail',
                params: {
                    id: courseId,
                }
            });
        },
        // 页面跳转
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageNumber = 1;
            this.getOrder();
            console.log(`每页 ${val} 条`);
        },
        current_change(val) {
            this.dictCurrentPage = val
        },
        currentChangeHandle(val) {
            this.pageNumber = val;
            this.getOrder();
            console.log(`当前页: ${val}`)
        },
    }
}
</script>

<style lang="scss" scoped>
    .el-pagination {
        text-align: center;
    }
    .myOrder-box {
        .title-box {
            padding-left: 30px;
            box-sizing: border-box;
            width: 950px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-bottom: 20px;
            .title-ul {
                display: flex;
                padding-top: 24px;
                box-sizing: border-box;
                .title-li {
                    margin-right: 45px;
                    text-align: center;
                    width: 80px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    cursor: pointer;
                }
                .active {
                    color: #FA4B66;
                    border-bottom: 3px solid #FA4B66;
                    padding-bottom: 22px;
                }
            }
        }
        .content-box {
            padding: 17px 25px;
            box-sizing: border-box;
            width: 950px;
            height: 771px;
            background: #FFFFFF;
            border-radius: 10px;
            .tab-box {
                .header-box {
                    margin: 0 auto;
                    margin-bottom: 20px;
                    width: 900px;
                    height: 50px;
                    line-height: 50px;
                    padding-left: 20px;
                    padding-right: 20px;
                    box-sizing: border-box;
                    background: #F6F6F6;
                    .tab-ul {
                        display: flex;
                        li {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                        }
                        .tab-one {
                            width: 386px;
                        }
                        .tab-two {
                            width: 158px;
                            text-align: center;
                        }
                        .tab-three {
                            width: 141px;
                            text-align: center;
                        }
                        .tab-four {
                            width: 172px;
                            text-align: center;
                        }
                    }
                }
                .tab-content-box {
                    margin: 0 auto;
                    width: 900px;
                    .orderNum-list-ul {
                        .orderNum-list-li {
                            margin-bottom: 20px;
                            padding: 21px 18px 20px;
                            box-sizing: border-box;
                            border: 1px solid #EEEEEE;
                            .orderNum-box {
                                padding-bottom: 21px;
                                margin-bottom: 28px;
                                box-sizing: border-box;
                                width: 860px;
                                border-bottom: 1px solid #EEEEEE;
                                display: flex;
                                .orderNum-left {
                                    margin-right: 27px;
                                    display: flex;
                                    align-items: center;
                                    .orderNum-icon {
                                        margin-right: 6px;
                                        display: block;
                                        width: 12px;
                                        height: 12px;
                                        background: #FF5A74;
                                    }
                                    .orderNum-text {
                                        font-size: 12px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #666666;
                                    }
                                    .orderNum-color {
                                        color: #FF5A74;
                                    }
                                }
                                .orderNum-right {
                                    font-size: 12px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                }
                            }
                            .orderNum-list {
                                display: flex;
                                .list-slide {
                                    line-height: 80px;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                }
                                .list-one {
                                    width: 386px;
                                    // height: 80px;
                                    flex-direction: column;
                                    justify-content: space-around;
                                    color: #333333;
                                    display: flex;
                                    line-height: 1px;
                                    p {
                                        cursor: pointer;
                                    }
                                }
                                .list-two {
                                    text-align: center;
                                    border-left: 1px solid #EEEEEE;
                                    border-right: 1px solid #EEEEEE;
                                    width: 158px;
                                    height: 80px;
                                    color: #FF5A74;
                                }
                                .list-three {
                                    border-right: 1px solid #EEEEEE;
                                    text-align: center;
                                    width: 141px;
                                    height: 80px;
                                    color: #999999;
                                }
                                .list-four {
                                    text-align: center;
                                    width: 172px;
                                    height: 80px;
                                    line-height: 26px;
                                    color: #999999;
                                    .list-play {
                                        color: #FF5A74;
                                        cursor: pointer;
                                    }
                                    .list-text {
                                        color: #999999;
                                        cursor: pointer;
                                        &:hover {
                                            color: #FF5A74;
                                        }
                                    }
                                    .four-allpay {
                                        line-height: 79px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
         }
    }
</style>