<template>
    <div class="profileBox">
        <!-- title栏 -->
        <div class="title-box">
            <ul>
                <li>
                    <a href="javascript:;">
                        个人资料
                    </a>
                </li>
            </ul>
        </div>
        <!-- 内容 -->
        <div class="content-box">
            <el-form :model="ruleForm" :rules="rules"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
                 <el-form-item label="昵称：" prop="name">
                    <el-input v-model="ruleForm.nickName" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="gender">
                    <el-radio-group v-model="ruleForm.gender">
                        <el-radio :label="0" >男</el-radio>
                        <el-radio :label="1">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="生日：" prop="birthday">
                    <el-input v-model="ruleForm.birthday" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="邮箱：" prop="email">
                    <el-input v-model="ruleForm.email" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="手机：" prop="mobile">
                    <el-input v-model="ruleForm.mobile" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" class="save-button">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    name: 'profile',
    data() {
        var checkPhone = (rule, value, callback) => {
            const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
            if (!value) {
                return callback(new Error('电话号码不能为空'))
            }
            setTimeout(() => {
            // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
            // 所以我就在前面加了一个+实现隐式转换

            if (!Number.isInteger(+value)) {
                callback(new Error('请输入数字值'))
            } else {
                if (phoneReg.test(value)) {
                callback()
                } else {
                callback(new Error('电话号码格式不正确'))
                }
            }
            }, 100)
        }
        var checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
                return callback(new Error('邮箱不能为空'))
            }
            setTimeout(() => {
            if (mailReg.test(value)) {
                callback()
            } else {
                callback(new Error('请输入正确的邮箱格式'))
            }
            }, 100)
        }
        return {
            ruleForm: {
                name: '',
                gender: '',
                birthday: '',
                email: '',
                mobile: ''
            },
            rules: {
                // name: [
                //     { required: true,message: '请设置昵称', trigger: 'blur' },
                //     { min: 3, max: 6, message: '长度在1 到 6个字符', trigger: 'blur' }
                // ],
                birthday: [
                    { required: true,message: '日期格式，例如 1994-05-23', trigger: 'blur' },
                    { min: 10, max: 10, message: '长度在10个字符', trigger: 'blur' }
                ],
                email: [
                    { required: true,validator: checkEmail, trigger: 'blur' }
                ],
                mobile: [
                    { required: true,type:'number',validator: checkPhone, trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        // submitForm(formName) {
        //     this.$refs[formName].validate((valid) => {
        //     if (valid) {
        //         alert('submit!');
        //     } else {
        //         console.log('error submit!!');
        //         return false;
        //     }
        //     });
        // },
        getData() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/info.html'
            }).then((res) => {
                this.ruleForm = res.data.result.member;
            })
        },
        sendData() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/save.html',
                data:{
                    nickName: this.ruleForm.nickName,
                    gender: this.ruleForm.gender,
                    mobile: this.ruleForm.mobile,
                    email: this.ruleForm.email,
                    birthday: this.ruleForm.birthday
                }
            }).then(() => {
                this.$message.success('个人资料已修改');
            })
        },
        submitForm() {
            // this.$refs[formName].validate((valid) => {
            // if (valid) {
            //     this.sendData()
            //     alert('submit!');
            // } else {
            //     console.log('error submit!!');
            //     return false;
            // }
            // });
            this.sendData()
        }
    }
}
</script>
<style lang="scss">
    .profileBox {
        .title-box {
            padding-left: 30px;
            box-sizing: border-box;
            width: 950px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-bottom: 20px;
            ul {
                display: flex;
                li {
                    a {
                        line-height: 70px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FA4B66;
                        border-bottom: 3px solid #FA4B66;
                        padding-bottom: 22px;
                    }
                }
            }
        }
        .content-box {
            padding-top: 58px;
            box-sizing: border-box;
            width: 950px;
            height: 771px;
            background: #FFFFFF;
            border-radius: 10px;
            .el-form-item__error {
                color: #F56C6C;
                font-size: 12px;
                line-height: 1;
                padding-top: 4px;
                position: absolute;
                top: 11px;
                left: 25%;
            }
            .save-button {
                width: 200px;
                background: #FA4B66;
                border-color: #FA4B66;
            }
        }
    }
</style>