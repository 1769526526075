<template>
    <div class="mymessage-box">
        <!-- title栏 -->
        <div class="title-box">
            <ul>
                <li>
                    <a href="javascript:;">
                        我的消息
                    </a>
                </li>
            </ul>
        </div>
        <!-- 内容 -->
        <div class="content-box" v-show="msgListBox">
            <ul>
                <li v-for="(item,index) in messageList.slice((dictCurrentPage- 1)*dictPageSize,dictCurrentPage*dictPageSize)" :key="index" @click="msgJump(item.id)">
                    <div class="inf-Box">
                        <div class="imformation-top">
                            <div class="imformation-box">
                                <div :class="{'imformation-notice':Number(item.type) === 0,'imformation-class':Number(item.type) === 1,'imformation-trade':Number(item.type) === 2}">
                                    <p v-if="Number(item.type) === 0">公告</p>
                                    <p v-if="Number(item.type) === 1">个人</p>
                                    <p v-if="Number(item.type) === 2">课程</p>
                                </div>
                                <p class="title">
                                    {{item.title}}
                                </p>
                            </div>
                            <div class="time-box">
                                <p class="time">{{item.dateTime}}</p>
                            </div>
                        </div>
                        <div class="imformation-content">
                            <p>{{item.content}}</p>
                        </div>
                    </div>
                </li>
            </ul>
            <el-pagination
                background
                layout="prev, pager, next"
                prev-text="<"
                next-text=">"
                :page-sizes="[2, 5, 10, 20]"
                :current-page="dictCurrentPage"
                :total="waitTotal"
                :page-size="dictPageSize"
                @current-change="current_change">
            </el-pagination>
        </div>
        <div class="content-msgBox" v-show="msgBox">
            <div class="titleBox">
                <p @click="toBack">返回上一级</p>
            </div>
            <div class="contentBox">
                <div class="headBox">
                    <p class="headTitle">{{messageDetail.title}}</p>
                    <p class="headSub">{{messageDetail.dateTime}}</p>
                </div>
                <div class="textBox">
                    <p>{{messageDetail.content}}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'MyMessage',
    data() {
        return {
            messageList: [],
            messageDetail: {},
            msgListBox: true,
            msgBox: false,
            newsId: null,
            waitTotal: 0,
            dictPageSize: 8,
            dictCurrentPage: 1,
        }
    },
    mounted() {
        this.getmassage();
    },
    methods: {
        msgJump(id) {
            this.msgListBox = false;
            this.msgBox = true;
            this.newsId = id;
            this.getdeatil();
        },
        toBack() {
            this.msgListBox = true;
            this.msgBox = false;
            this.getmassage();
        },
        // 消息详细信息
        getdeatil() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/notice/detail.html',
                data: {
                    noticeId: this.newsId
                }
            }).then((res) => {
                this.messageDetail = res.data.result;
                this.waitTotal = this.messageDetail.length;
            })
        },
        // 获取信息列表
        getmassage() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/notice/myNotice.html'
            }).then((res) => {
                this.messageList = res.data.result.list;

            })
        },
        current_change(val) {
            this.dictCurrentPage = val;
        }
    }
}
</script>

<style lang="scss" scoped>
    .el-pagination {
        text-align: center;
    }
    .mymessage-box {
        .title-box {
            padding-left: 30px;
            box-sizing: border-box;
            width: 950px;
            height: 70px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-bottom: 20px;
            ul {
                display: flex;
                li {
                    a {
                        line-height: 70px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FA4B66;
                        border-bottom: 3px solid #FA4B66;
                        padding-bottom: 22px;
                    }
                }
            }
        }
        .content-box {
            padding: 40px 25px;
            box-sizing: border-box;
            width: 950px;
            height: 771px;
            background: #FFFFFF;
            border-radius: 10px;
            ul {
                li {
                    .inf-Box {
                        cursor: pointer;
                        margin-bottom: 20px;
                        padding-bottom: 20px;
                        border-bottom: 1px solid #EEEEEE;
                        .imformation-top {
                            margin-bottom: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .imformation-box {
                                display: flex;
                                align-items: center;
                                .imformation-notice {
                                    margin-right: 10px;
                                    width: 40px;
                                    height: 24px;
                                    background: #FF8400;
                                    border-radius: 3px;
                                    text-align: center;
                                    color: #FFFFFF;
                                    line-height: 24px;
                                }
                                .imformation-class {
                                    margin-right: 10px;
                                    width: 40px;
                                    height: 24px;
                                    background: #FA4B66;
                                    border-radius: 3px;
                                    text-align: center;
                                    color: #FFFFFF;
                                    line-height: 24px;
                                }
                                .imformation-trade {
                                     margin-right: 10px;
                                    width: 40px;
                                    height: 24px;
                                    background: #28C965;
                                    border-radius: 3px;
                                    text-align: center;
                                    color: #FFFFFF;
                                    line-height: 24px;
                                }
                                .title {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                            }
                            .time-box {
                                font-size: 12px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                        .imformation-content {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            line-height: 24px;
                        }
                    }
                }
            }
         }
        .content-msgBox {
            padding: 40px 25px;
            box-sizing: border-box;
            width: 950px;
            height: 771px;
            background: #FFFFFF;
            border-radius: 10px;
            .titleBox {
                margin-bottom: 20px;
                width: 100%;
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #EEEEEE;
                p {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    cursor: pointer;
                    &:hover {
                        color: #FA4B66;
                    }
                }
            }
            .contentBox {
                .headBox {
                    margin-bottom: 20px;
                    text-align: center;
                    .headTitle {
                        margin-bottom: 20px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                    }
                    .headSub {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                    }
                }
                .textBox {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
</style>