<template>
    <div class="userInfo-box">
        <!-- 头部 -->
        <div class="head">
            <div class="head-content">
                <div class="head-left">
                    <div class="head-icon">
                        <el-upload
                        class="avatar-uploader"
                        action="api/api/v2/yun/member/uploadHeadPhoto.html"
                        :show-file-list="false"
                        :headers="headers"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                        >
                        <img v-if="avatarUrl" :src="avatarUrl" class="avatar" />
                        
                        </el-upload>
                    </div>
                    <div class="head-info">
                        <div class="info-title">
                            {{avatarName}}，欢迎您~
                        </div>
                        <div class="info-sub">
                            <p class="id">ID:{{memberId}}</p>
                            <p class="time">注册时间：{{registerDate}}</p>
                        </div>
                    </div>
                </div>
                <div class="head-right">
                    <div class="num-slide">
                        <p class="number">{{courseCount}}</p>
                        <p class="num-text">个课程</p>
                    </div>
                    <div class="right-line">
                        <span >/</span>
                    </div>
                    <div class="num-slide">
                        <p class="number">{{tikuCount}}</p>
                        <p class="num-text">个题库</p>
                    </div>
                    <div class="right-line">
                        <span >/</span>
                    </div>
                    <div class="num-slide">
                        <p class="number">{{questions}}</p>
                        <p class="num-text">个答疑</p>
                    </div>
                    <div class="right-line">
                        <span >/</span>
                    </div>
                     <div class="num-slide">
                        <p class="number">{{classroom}}</p>
                        <p class="num-text">个班级</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 内容 -->
        <div class="userInfo-content">
            <div class="userInfo-wrap">
                <div class="content-left">
                    <div class="sub-left">
                        <p>课程信息</p>
                        <ul>
                            <li :class="{active: currentTab == 'MyClassAll'}" @click="toggleTab('MyClassAll')">
                                <i class="iconfont icon-shipin1"></i>
                                我的课程
                            </li>
                            <li :class="{active: currentTab == 'ClassLive'}" @click="toggleTab('ClassLive')">
                                <i class="iconfont icon-shipin"></i>
                                班级直播
                            </li>
                            <li :class="{active: currentTab == 'LessonCollection'}" @click="toggleTab('LessonCollection')">
                                <i class="iconfont icon-shoucang"></i>
                                课程收藏
                            </li>
                            <li :class="{active: currentTab == 'SubjectRecord'}" @click="toggleTab('SubjectRecord')">
                                <i class="iconfont icon-zuotijilu"></i>
                                做题记录
                            </li>
                        </ul>
                    </div>
                    <div class="left-line"></div>
                    <!-- <div class="sub-left">
                        <p>我的题库</p>
                        <ul>
                            <li>
                                <i></i>开始做题
                            </li>
                            <li>
                                做题记录
                            </li>
                        </ul>
                    </div> -->
                    <div class="sub-left">
                        <p>账户管理</p>
                        <ul>
                            <li :class="{active: currentTab == 'MyOrder'}" @click="toggleTab('MyOrder')">
                                <i class="iconfont icon-icon-test"></i>
                                我的订单
                            </li>
                        </ul>
                    </div>
                    <div class="left-line"></div>
                    <div class="sub-left">
                        <p>账号设置</p>
                        <ul>
                            <li :class="{active: currentTab == 'Profile'}" @click="toggleTab('Profile')">
                                <i class="iconfont icon-gerenziliao"></i>
                                个人资料
                            </li>
                            <li :class="{active: currentTab == 'Changeassword'}" @click="toggleTab('Changeassword')">
                                <i class="iconfont icon-change_password"></i>
                                修改密码
                            </li>
                        </ul>
                    </div>
                    <div class="left-line"></div>
                    <div class="sub-left">
                        <p>系统信息</p>
                        <ul>
                            <li :class="{active: currentTab == 'MyMessage'}" @click="toggleTab('MyMessage')">
                                <i class="iconfont icon-wodexiaoxi"></i>
                                我的消息
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="content-right">
                    <!-- <div class="right-top"></div> -->
                    <profile :is="currentTab"></profile>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Profile from '../components/userInfo/Profile.vue'
import Changeassword from '../components/userInfo/ChangePassword.vue'

import MyClassAll from '../components/userInfo/MyClassAll.vue'
import ClassLive from '../components/userInfo/ClassLive.vue'
import LessonCollection from '../components/userInfo/LessonCollection.vue'
import SubjectRecord from '../components/userInfo/SubjectRecord.vue'

import MyOrder from '../components/userInfo/MyOrder.vue'
import MyMessage from '../components/userInfo/MyMessage.vue'


export default {
    name: 'userinfo',
    data(){
        return {
            currentTab: 'MyClassAll',
            avatarUrl: '',
            avatarName: null, // 用户名字
            questions: null, // 答疑个数
            classroom: null, // 班级个数
            courseCount: null, // 课程个数
            tikuCount: null, // 题库个数
            memberId: null, // ID
            registerDate: null // 注册时间
        }
    },
    components: { Profile,Changeassword,MyClassAll,ClassLive,LessonCollection,SubjectRecord,MyOrder,MyMessage },
    mounted() {
        this.getUserInfo();
    },
    metaInfo: {
        title: '个人中心-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    computed: {
        headers() {
            return{
                "token": localStorage.getItem('token') // 直接从本地获取token就行
            }
        }
    },
    methods: {
        toggleTab: function(tab) {
            this.currentTab = tab;
        },
        getUserInfo() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/index.html',
            }).then((res) => {
                this.avatarUrl = res.data.result.headPhotoUrl;
                this.avatarName = res.data.result.name;
                this.memberId = res.data.result.memberId;
                this.tikuCount = res.data.result.tikuCount;
                this.questions = res.data.result.questions;
                this.courseCount = res.data.result.courseCount;
                this.classroom = res.data.result.classroom;
                this.registerDate = res.data.result.registerDate;
            })
        },
        handleAvatarSuccess() {
            this.getUserInfo();
        },
        beforeAvatarUpload(file) {
             // 类型
            const isJPG = file.type === "image/jpeg";
            // 大小
            const isLt2M = file.size / 1024 / 1024 < 2;
            // 类型限制
            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            // 大小限制
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        }
    }
}
</script>
<style lang="scss">
    .userInfo-box {
        margin-top: 70px;
        // 头部
        .head {
            width: 100%;
            min-width: 1200px;
            height: 300px;
            background: url("../assets/img/infoBanner.jpg") no-repeat;
            background-size: 100% 100%;
            padding-top: 85px;
            box-sizing: border-box;
            .head-content {
                margin: 0 auto;
                position: relative;
                width: 1200px;
                display: flex;
                justify-content: space-between;
                .head-left {
                    display: flex;
                    .head-icon {
                        margin-right: 20px;
                        width: 140px;
                        height: 140px;
                        border-radius: 50%;
                        .avatar {
                            width: 140px;
                            height: 140px;
                            border-radius: 50%;
                        }
                    }
                    .head-info {
                        margin-top: 23px;
                        .info-title {
                            margin-bottom: 14px;
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                        .info-sub {
                            .id {
                                margin-bottom: 12px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FFC601;
                            }
                            .time {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #CCCCCC;
                            }
                        }
                    }
                }
                .head-right {
                    width: 439px;
                    height: 50px;
                    margin-top: 35px;
                    display: flex;
                    justify-content: space-around;
                    .num-slide {
                        text-align: center;
                        .number {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFD200;
                        }
                        .num-text {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                    }
                    .right-line {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 50px;
                    }
                }
            }
        }

        // 内容
        .userInfo-content {
            padding-top: 60px;
            box-sizing: border-box;
            width: 100%;
            min-width: 1200px;
            background: #e9eaed;
            .userInfo-wrap {
                padding-bottom: 80px;
                box-sizing: border-box;
                margin: 0 auto;
                width: 1200px;
                display: flex;
                justify-content: space-between;
                .content-left {
                    padding-top: 23px;
                    box-sizing: border-box;
                    width: 230px;
                    height: 861px;
                    background: #FFFFFF;
                    border-radius: 10px;
                    .left-line {
                        margin: 0 auto;
                        margin-top: 27px;
                        margin-bottom: 25px;
                        width: 210px;
                        height: 1px;
                        background: #EEEEEE;
                    }
                    .sub-left {
                        p {
                            margin-bottom: 24px;
                            padding-left: 40px;
                            box-sizing: border-box;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                        }
                        ul {

                            .active {
                                background: rgba(250,75,102, 0.1);
                                color: #FA4B66;
                                position: relative;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 3px;
                                    height: 36px;
                                    background: #FA4B66;
                                }
                            }
                            li {
                                padding-left: 40px;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 36px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                                cursor: pointer;
                                i {
                                    margin-right: 6px;
                                    display: block;
                                    color: #999999;
                                }
                            }
                        }
                    }
                    
                }
                .content-right {
                    width: 950px;
                    .right-top {
                        height: 70px;
                        background: #FFFFFF;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
</style>